<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_information')"
                        :isFilter="false"
                        :actions="actions"
                        :selected-menu="selectedMenu"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_information')"
                              :isFilter="false"
                              :selected-menu="selectedMenu"
                />
            </template>
            <div class="d-flex flex-column flex-lg-row">
                <div class="mr-lg-5">
                    <info-page :studentProgram="studentProgram" :editMode="editMode"/>
                </div>
                <div class="mr-lg-5">
                    <menu-page v-model="selectedMenu"
                               :studentProgram="studentProgram"
                               :forStudent="true"
                               :menu-data="menuData"/>
                    <b-dropdown
                        :text="computeDropdownMenuName"
                        block
                        split
                        split-variant="primary"
                        variant="primary"
                        class="d-block d-lg-none secondary-border-lighter btn-outline-secondary mb-3"
                    >
                        <b-dropdown-item v-for="menu in menuData"
                                         :key="'dropdownMenu'+menu.slug"
                                         @click="selectedMenu = menu.slug"
                                         v-if="menu.showStudent && checkPermission(menu.permission) && menu.show">
                            {{ menu.title }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="flex-grow-1 w-100 w-lg-0" v-if="studentProgram && studentProgram.id">
                    <general-information v-if="selectedMenu == 'general_information'"
                                         :studentProgram="studentProgram"
                    />
                    <identity-information v-if="selectedMenu == 'identity_information'"
                                          @updatedData="getStudent"
                                          :studentProgram="studentProgram"
                                          :editMode="editMode"
                    />
                    <foreign-student-credential v-if="selectedMenu == 'foreign_student_credentials'"
                                                @updatedData="getStudent"
                                                :studentProgram="studentProgram"
                                                :editMode="editMode"
                    />
                    <address-information v-if="selectedMenu == 'address_information'"
                                         @updatedData="getStudent"
                                         :studentProgram="studentProgram"
                                         :edit-residence-address="true"
                                         :editMode="true"
                    />
                    <family-information v-if="selectedMenu == 'family_information'"
                                        @updatedData="getStudent"
                                        :studentProgram="studentProgram"
                                        :editMode="editMode"
                    />
                    <military-information v-if="selectedMenu == 'military_information'"
                                          @updatedData="getStudent"
                                          :studentProgram="studentProgram"
                                          :editMode="editMode"
                    />
                    <health-information v-if="selectedMenu == 'health_information'"
                                        @updatedData="getStudent"
                                        :studentProgram="studentProgram"
                                        :editMode="editMode"
                    />
                    <university-information v-if="selectedMenu == 'university_information'"
                                            @updatedData="getStudent"
                                            :studentProgram="studentProgram"
                                            :editMode="editMode"
                    />
                    <contact-information v-if="selectedMenu == 'contact_information'"
                                         @updatedData="getStudent"
                                         :studentProgram="studentProgram"
                                         :editMode="true"
                    />
                    <score-information v-if="selectedMenu == 'score_information'"
                                       :studentProgram="studentProgram"
                                       :editMode="editMode"
                    />
                    <student-education v-if="selectedMenu == 'student_education'"
                                       :studentProgram="studentProgram"
                                       :editMode="editMode"
                    />
                    <undergraduate-vertical-transfer-information v-if="selectedMenu == 'undergraduate_vertical_transfer_information'"
                                                                 @updatedData="getStudent"
                                                                 :studentProgram="studentProgram"
                    />
                    <documents v-if="selectedMenu == 'documents'"
                               :studentProgram="studentProgram"
                    />
                    <missing-document v-if="selectedMenu == 'missing_document'"
                                      @updatedData="getStudent"
                                      :studentProgram.sync="studentProgram"
                    />
                    <note v-if="selectedMenu == 'notes'"
                          :studentProgram="studentProgram"
                    />
                    <discipline-process v-if="selectedMenu == 'discipline_process'"
                                        :studentProgram="studentProgram"
                    />
                </div>
            </div>
            <CommonModal ref="DocumentModal" size="lg" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('documents') }}
                </template>
                <template v-slot:CommonModalContent>
                    <div class="mb-2 border rounded p-2 text-center bg-dark text-white" v-if="studentProgram">
                        {{ studentProgram.student_number }} - {{ studentProgram.name }} {{ studentProgram.surname }}
                    </div>
                    <b-row>
                        <template>
                            <b-col
                                cols="6"
                                v-for="(item, itemKey) in computeDocuments"
                                :key="'document' + itemKey"
                                v-if="checkPermission(item.permission)"
                            >
                                <b-list-group class="pe-auto" v-for="(lang, langKey) in item.language">
                                    <b-list-group-item class="mb-1 text-center"   :style="{ cursor: 'pointer' }" :key="'lang' + langKey"  @click="item.callback(lang)">{{ item.text.toUpper()}}</b-list-group-item>
                                </b-list-group>
                            </b-col>
                        </template>
                    </b-row>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    // Page
    import AddressInformation from './addressInformation/Index';
    import ContactInformation from './contactInformation/Index';
    import DisciplineProcess from './disciplineProcess/Index'
    import Documents from './documents/Index';
    import FamilyInformation from './familyInformation/Index';
    import ForeignStudentCredential from './foreignStudentCredential/Index';
    import GeneralInformation from './generalInformation/Index';
    import HealthInformation from './healthInformation/Index';
    import IdentityInformation from './identityInformation/Index';
    import InfoPage from './InfoPage';
    import MenuPage from './MenuPage';
    import MilitaryInformation from './militaryInformation/Index';
    import MissingDocument from './missingDocument/Index';
    import Note from './note/Index';
    import ScoreInformation from './scoreInformation/Index';
    import StudentEducation from './studentEducation/Index';
    import UndergraduateVerticalTransferInformation from './undergraduateVerticalTransferInformation/Index';
    import UniversityInformation from './universityInformation/Index';

    // Services
    import StudentProgramService from '@/services/StudentProgramService';

    // Data
    import documentData from '@/modules/students/data/Documents';
    import menuData from '@/modules/students/data/Menus';

    // Component
    import CommonModal from '@/components/elements/CommonModal';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            AddressInformation,
            ContactInformation,
            DisciplineProcess,
            Documents,
            FamilyInformation,
            ForeignStudentCredential,
            GeneralInformation,
            HealthInformation,
            IdentityInformation,
            InfoPage,
            MenuPage,
            MilitaryInformation,
            MissingDocument,
            Note,
            ScoreInformation,
            StudentEducation,
            UndergraduateVerticalTransferInformation,
            UniversityInformation,

            CommonModal
        },
        metaInfo() {
            return {
                title: this.$t('student')
            }
        },
        data() {
            return {
                formProcess: null,
                formId: null,

                user: null,
                studentProgramId: null,
                studentProgram: {},

                menuData: null,
                selectedMenu: 'general_information',
                editMode: false,
                actions: [
                    {
                        text: this.$t('documents'),
                        class: 'ri-file-list-line',
                        permission: 'studentprogram_show',
                        callback: () => {
                            this.showDocumentModal();
                        }
                    }
                ]
            }
        },
        computed: {
            computeDropdownMenuName:{
                get() {
                    let selected = (this.menuData || []).find(item => item.slug == this.selectedMenu)
                    return selected ? this.toUpperCase(selected.title) : '';
                },
                set() {

                }
            },
            computeDocuments() {
                let documents = [];

                if (this.studentProgram && this.studentProgram.student_id && this.studentProgram.student_number) {
                    documents = documentData({
                        id: this.studentProgram.id,
                        student_number: this.studentProgram.student_number,
                        name: this.studentProgram.name,
                        surname: this.studentProgram.surname,
                        gender: this.studentProgram.gender,
                        nationality_code: this.studentProgram.nationality_code,
                        program_level: this.studentProgram.program_level,
                        registration_type:this.studentProgram.registration_type,
                        registration_type_text:this.studentProgram.registration_type_text,
                        student_status:this.studentProgram.student_status,
                    })
                }

                return documents;
            }
        },
        async mounted() {
            this.user = this.$store.getters['auth/getUser'];
            this.studentProgramId = this.user.student.id;
            if (this.studentProgramId) {
                await this.getStudent()
                this.menuData = menuData(null, this.user.person);
                this.selectedDefaultValue()
            } else {
                this.$router.push('/404');
            }
        },
        methods: {
            async getStudent() {
                await StudentProgramService.get(this.studentProgramId)
                                           .then(response => {
                                               this.studentProgram = response.data.data;
                                           })
                                           .catch(e => {
                                               this.showErrors(e);
                                           })
            },
            showDocumentModal() {
                this.$refs.DocumentModal.$refs.commonModal.show()
            },
            selectedDefaultValue() {
                let selected = this.menuData.find(item => item.slug == this.selectedMenu)
                this.computeDropdownMenuName = selected ? this.toUpperCase(selected.title) : '';
            }
        }
    }
</script>

